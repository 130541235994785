import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { CtaPrimary } from "../components/elements/buttons.js"
import SuccessSVG from "../images/confirmed.svg"

const SectionTitle = styled.h2`
  font-size: 30px;
  line-height: 1.5556;
  font-weight: 700;
  color: inherit;
  margin: 0 auto;
  text-align: left;
  letter-spacing: -0.04em;
  width: 100%;
`
const ImgWrap = styled.img`
  width: 35%;
  margin-left: auto;
  margin-bottom: 20px;
  opacity: 0.3;
  z-index: -1;
  position: absolute;
  right: 10%;
  top: 24vh;
`
const BtnWrap = styled.div`
  margin: 2em auto 0 0;
  width: 25%;
  min-width: 120px;
`
const Wrap = styled.div`
  width: 100%;
  height: 100vh;
  padding: 10%;
`
const ContentWrap = styled.div`
  width: 40%;
  min-width: 300px;
`
const PaymentSuccess = () => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem("__buy")
  }
  return (
    <Layout>
      <Wrap>
        <ContentWrap>
          <SectionTitle>Payment Complete</SectionTitle>
          <p>
            Looks like the payment went through! Check your email, you should
            maybe receive a confirmation email from us. In the meantime we'll
            get the onboarding process started.
          </p>
          <BtnWrap>
            <CtaPrimary
              backgroundColor={"black"}
              height={"20px"}
              label={"To Profile"}
              link={"/app/dashboard"}
            />
          </BtnWrap>
          <ImgWrap src={SuccessSVG} />
        </ContentWrap>
      </Wrap>
    </Layout>
  )
}
export default PaymentSuccess
